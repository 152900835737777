<template>
    <div>
        <van-nav-bar class="navbar" title="房屋详情" fixed left-arrow  @click-left="leftReturn" />
        <div class="swipe">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item,index) in roomImages" :key="index">
                    <div class="image">
                        <img :src="item.roomPhotoPath">
                    </div>
                </van-swipe-item>
            </van-swipe>
            <div class="top-title">
                <div class="small-text-10">
                    {{roomImages.length}}图
                </div>
            </div>
        </div>
        <!--收藏分享部分-->
        <div class="house-describe">
            <div class="house-describe-position">
             <!--{{house.houseType}}-->
            <span class="house-describe-position" v-show="initroom.roomType == 0">整租</span>
            <span class="house-describe-position" v-show="initroom.roomType == 1">合租</span>
            <span class="house-describe-position" v-show="initroom.roomType == 2">集寓</span>
               <!--商圈名称-->
                <span class="house-describe-position-span">·{{initroom.roombusinessCircleName}}</span>
            </div>
            <div class="house-describe-icon1" @click="collect">
                <div :class="collectActive?'house-describe-icon1-leftActive':'house-describe-icon1-left'"></div>
                <div class="house-describe-icon1-text">
                    <div class="small-text-8" :class="{'small-text-8Active':collectActive}">收藏</div>
                </div>
            </div>
            <div class="house-describe-icon2">
                <div class="house-describe-icon1-right"></div>
                <div class="house-describe-icon1-text">
                    <div class="small-text-8">分享</div>
                </div>
            </div>
        </div>
        <!--价格部分-->
        <div class="infos">
            <div class="infos-price">{{initroom.roomRent}}元</div>
            <div class="infos-month">/月</div>
            <div :class="item==='地铁房'?'infos-info-1':'infos-info-2'" v-for="(item,index) in roomFeatureList" :key="index">
                <div class="smallText-10">
                    {{item.roomCharacteristicName}}
                </div>
            </div>
        </div>
        <!--详细描述部分-->
        <div class="details">
            <div class="details-detail-left">
                <div class="details-detail-top">使用面积</div>
                <div class="details-detail-bottom">{{initroom.roomArea}}m²</div>
            </div>
            <div class="details-line"></div>
            <div class="details-detail">
                <div class="details-detail-top">户型</div>
                <div class="details-detail-bottom">{{initroom.roomApartment}}</div>
            </div>
            <div class="details-line"></div>
            <div class="details-detail">
                <div class="details-detail-top">楼层</div>
                <div class="details-detail-bottom">{{initroom.roomFloor}}/{{initroom.totalFloor}}</div>
            </div>
        </div>
        <!--入住时间-->
        <div class="inTime">
            <div class="inTime-icon"></div>
            <div class="inTime-info">{{initroom.roomEntryTime}}</div>
        </div>
        <!--具体地址-->
        <div>
            <div class="position">
                <div class="position-icon"></div>
                <div class="position-text">位于{{initroom.roomFuzzyAddress}}</div>
                <div class="position-link">查看地址</div>
            </div>
        </div>
        <!--家具具体信息部分-->
        <div class="houseDetails">
            <div class="houseDetails-text">房屋信息</div>
            <div class="furnitures">
                <div class="furniture-one" v-for="(item,index) in roomItemList" :key="index">
                    <div class="furniture-one-icon">
<!--                        <img src="../../../assets/images/houseResourcesImg/bed.png">-->
                        <img :src="item.itemPhotoPath">
                    </div>
                    <div class="furniture-one-text">{{item.itemDescription}}</div>
                </div>
            </div>
            <!--房屋描述-->
            <div class="houseDetails-describe">房屋描述</div>
            <div class="houseDetails-describe-details" :class="showActive?'show':'hide'">
                {{initroom.roomDescription}}
            </div>
            <div class="bottom-box">
                <div class="showOrHidden" @click="showOrHidden">{{showActive?'收起':'显示全部'}}</div>
            </div>
            <!--底部图片-->
            <div class="bottom-img"></div>
        </div>
        <!--底部固定栏-->
        <div class="bottom-fixed">
            <div class="bottom-fixed-signed">
                <div class="bottom-fixed-signed-icon"></div>
                <div class="bottom-fixed-text">
                    <div @click="signContract" class="bottom-fixed-text-11">签约</div>
                </div>
            </div>
            <div class="bottom-fixed-order">
                <div class="bottom-fixed-order-icon"></div>
                <div class="bottom-fixed-text">
                    <div @click="scheduled" class="bottom-fixed-text-11">预定</div>
                </div>
            </div>
            <div @click="appointmentSeeHouse" class="bottom-fixed-orderLook">预约看房</div>
            <div @click="telephoneConsultation" class="bottom-fixed-phoneRelation">电话咨询</div>
        </div>
    </div>
</template>

<script>
    import { NavBar,Swipe, SwipeItem} from 'vant';
    import {roomDetail} from "../../../getData/getData";
    import {responseUtil} from "../../../libs/rongxunUtil";
    export default {
        name: "houseDetails",
        data(){
          return{
                initroom:[{
                  userType:'' ,          //	用户类型
                  roomCollection_id:'' , //	房源收藏id
                  room_id:'' ,           //	房源id
                  roomType:'' ,          //	房源类型
                  roombusinessCircleName:'' ,//	商圈名称
                  chargeStaffMobile:'' , //负责人电话
                  roomFuzzyAddress:'' ,  //	房源模糊地址
                  roomApartment:'' ,     //	房源户型
                  roomArea:'' ,          //	房源面积
                  totalFloor:'' ,        //	房源总层数
                  roomFloor:'' ,         //	房源楼层
                  roomDescription:'' ,   //	房屋描述
                  longitude:'' ,         //	经度
                  latitude:'' ,          //	纬度
                  PlatformSupport:'' ,   //	平台保障(富文本)
                  isCollect:'' ,         //	是否被收藏(0 否 1 是)
                  roomEntryTime:'' ,     //	可入住时间
                  roomRent:'' ,          //	租金
                }],
                roomImages:[],
                roomItemList:[],
                roomFeatureList:[],
              showActive:false,//默认隐藏
              collectActive:false,//默认未收藏
              house:{//房屋属性
                  houseType:'合租',
                  housePosition:'天通苑南 天通苑西区 朝南主卧·五居室 2号房间',
                  housePrice:'809',
                  houseUsingArea:'16.8',
                  houseRoomType:'5室2厅1卫',
                  houseFloor:'5',
                  houseTotalFloors:'7',
                  houseCanIn:'详细地址',
                  houseRealPosition:'沙河口区/春柳街道',
                  houseDetail:'惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V惹我收到政府的深惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V惹我收到政府的深V'
              },
              houseGoods:['飘窗','loft','地铁房'],

              houseImages:[
                  {url:require('../../../assets/images/houseImg.png')},{url:require('../../../assets/images/houseImg.png')},
                  {url:require('../../../assets/images/houseImg.png')},{url:require('../../../assets/images/houseImg.png')}
              ],
              furnitureList:[
                  {name:'床',url:require('../../../assets/images/houseResourcesImg/bed.png')},
                  {name:'洗衣机',url:require('../../../assets/images/houseResourcesImg/washing Machine.png')},
                  {name:'微波炉',url:require('../../../assets/images/houseResourcesImg/microwave.png')},
                  {name:'书桌',url:require('../../../assets/images/houseResourcesImg/table.png')},
                  {name:'沙发',url:require('../../../assets/images/houseResourcesImg/sofa.png')},
                  {name:'冰箱',url:require('../../../assets/images/houseResourcesImg/refrigerator.png')},
                  {name:'马桶',url:require('../../../assets/images/houseResourcesImg/toilet.png')}
              ],
               //roomId:''         //赵
          }
        },
            mounted: function () {
                    // 初始化
                    let initData = {}
                    //initData.user_id = '109'    //赵
                    initData.user_id = '116'  //原
                    // 接收参数
                    initData.room_id =  this.$route.params.room_id
                    this.roomId=initData.room_id
                    this.initData(initData)
                },
        methods:{
            //签约
            signContract(){
                this.$router.push({
                    name : 'NewContract',
                    query : {
                      roomId:this.roomId        //赵
                    }
                })
            },

            //预定
            scheduled(){
                this.$router.push({
                    name : 'preContract',
                    query : {

                    }
                })
            },
            //预约看房
            appointmentSeeHouse(){
                this.$router.push({
                    name : 'addAppointment',
                    query : {
						room_id : this.$route.params.room_id
                    }
                })
            },

            //电话咨询
            telephoneConsultation(){
                this.$router.push({
                    name : '',
                    query : {

                    }
                })
            },
         //返回上一层
                    leftReturn(){
                        this.$router.go(-1);
                    },
          // 页面初始化
                    initData: function (initData) {
                        var that = this
                        roomDetail(initData).then(function (response) {
                            responseUtil.dealResponse(that, response, () => {
                               //  console.log(response.data.data)
                                that.initroom = response.data.data
                                that.roomImages= response.data.data.roomPhotoList
                                that.roomItemList= response.data.data.roomItemList
                                that.roomFeatureList= response.data.data.roomFeatureList
                            })
                        })
                    },
            showOrHidden(){
                this.showActive = !this.showActive
            },
            collect(){
                this.collectActive = !this.collectActive
            }
        },
        components:{
            [NavBar.name]:NavBar,
            [Swipe.name]:Swipe,
            [SwipeItem.name]:SwipeItem
        }
    }
</script>

<style scoped>
    .swipe{
        top: 52px;
        width: 100%;
        height: 281px;
    }
    .my-swipe{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .my-swipe .van-swipe-item {
        width: 100%;
        height: 100%;
        color: #fff;
        font-size: 20px;
        text-align: center;
    }
    .image{
        color: red;
    }
    img{
        width: 100%;
        height: 100%;
    }
    .top-title{
        position: absolute;
        width: 29px;
        height: 14px;
        left: 333px;
        top: 257px;
        border-radius: 8px;
        background-color: rgba(0, 0, 0, 0.2);
        color: rgba(255, 255, 255, 1);
        font-size: 10px;
        text-align: center;
        font-family: PingFangSC-Regular;
    }
    .small-text-10{
        width: 100%;
        height: 100%;
        font-size: 20px;
        -webkit-transform: scale(0.5);
        white-space: nowrap;
        margin-top: -4px;
    }
    .house-describe{
        display: flex;
        width: 100%;
        height: auto;
    }
    .house-describe-position{
        width: 270px;
        margin-left: 15px;
        margin-top: 20px;
        font-weight: bold;
        height: auto;
        line-height: 28px;
        font-size: 18px;
        color: #FF5D3B;
    }
    .house-describe-position-span{
        line-height: 28px;
        color: black;
    }
    .house-describe-icon1{
        width: 22px;
        height: 40px;
        margin-left: 11px;
        margin-top: 21px;
    }
    .house-describe-icon2{
        width: 22px;
        height: auto;
        margin-left: 18px;
        margin-top: 21px;
    }
    .house-describe-icon1-left{
        width: 100%;
        height: 22px;
        background-image: url("../../../assets/images/houseResourcesImg/collection.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .house-describe-icon1-leftActive{
        width: 100%;
        height: 22px;
        background-image: url("../../../assets/images/houseResourcesImg/collection-active.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .house-describe-icon1-right{
        width: 100%;
        height: 22px;
        background-image: url("../../../assets/images/houseResourcesImg/share.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .house-describe-icon1-text{
        width: 16px;
        height: 11px;
        text-align: center;
        line-height: 11px;
        margin-top: 3px;
        color: rgba(153, 153, 153, 1);
        font-size: 16px;
        text-align: left;
        font-family: PingFangSC-Regular;
        white-space: nowrap;
    }
    .small-text-8{
        width: 100%;
        height: 100%;
        -webkit-transform: scale(0.5);
    }
    .small-text-8Active{
        width: 100%;
        height: 100%;
        color: rgba(255, 93, 59, 1);
        -webkit-transform: scale(0.5);
    }
    .infos{
        width: 364px;
        height: 50px;
    }
    .infos-price{
        float: left;
        width: auto;
        height: 32px;
        line-height: 35px;
        margin: 7px 0 0 20px;
        color: rgba(255, 93, 59, 1);
        font-size: 22px;
        text-align: left;
        font-family: Oswald-Regular;
        white-space: nowrap;
    }
    .infos-month{
        float: left;
        width: 21px;
        height: 20px;
        line-height: 20px;
        margin-left: 5px;
        margin-top: 18px;
        color: rgba(255, 93, 59, 1);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .infos-info-1{
        float: right;
        width: auto;
        height: 14px;
        margin-top: 21px;
        margin-right: 1px;
        color: rgba(255, 93, 59, 1);
        font-family: PingFangSC-Regular;
        -webkit-transform: scale(0.83);
        background-color: rgba(233, 91, 46, 0.2);
        border-radius: 2px;
    }
    .infos-info-2{
        float: right;
        width: auto;
        height: 14px;
        margin-top: 21px;
        margin-right: 1px;
        color: rgba(86, 155, 236, 1);
        font-family: PingFangSC-Regular;
        -webkit-transform: scale(0.83);
        background-color: rgba(86, 155, 236, 0.2);
        border-radius: 2px;
    }
    .smallText-10{
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 15px;
        font-size: 12px;
        -webkit-transform: scale(0.83);
    }
    .details{
        width: 345px;
        height: 77px;
        margin-left: 17px;
        background-color: white;
        border-radius: 8px;
        display: flex;
    }
    .details-detail-left{
        width: 115px;
    }
    .details-detail{
        width: 114px;
    }
    .details-line{
        width: 1px;
        height: 40px;
        margin-top: 22px;
        background-color: rgba(239, 239, 239, 1);
    }
    .details-detail-top{
        width: 100%;
        height: 17px;
        margin-top: 15px;
        line-height: 17px;
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        text-align: center;
        font-family: PingFangSC-Regular;
    }
    .details-detail-bottom{
        width: 100%;
        height: 22px;
        margin-top: 8px;
        font-weight: bold;
        line-height: 22px;
        color: rgba(34, 34, 34, 1);
        font-size: 16px;
        text-align: center;
        font-family: PingFangSC-Semibold;
    }
    .inTime{
        width: 345px;
        height: 40px;
        margin-top: 10px;
        margin-left: 17px;
        background-color: white;
        display: flex;
        border-radius: 8px;
        align-items: center;
    }
    .inTime-icon{
        width: 20px;
        height: 20px;
        margin-left: 17px;
        background-image: url("../../../assets/images/houseResourcesImg/time.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .inTime-info{
        width: auto;
        height: 18px;
        line-height: 21px;
        margin-left: 9px;
        color: rgba(255, 93, 59, 1);
        font-size: 13px;
        text-align: left;
        font-family: PingFangSC-Regular;
        white-space: nowrap;
    }
    .position{
        position: relative;
        width: 345px;
        height: 40px;
        margin-top: 10px;
        margin-left: 17px;
        background-color: white;
        display: flex;
        border-radius: 8px;
        align-items: center;
    }
    .position-icon{
        width: 22px;
        height: 22px;
        margin-left: 15px;
        background-image: url("../../../assets/images/houseResourcesImg/position.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .position-text{
        width: auto;
        height: 18px;
        margin-left: 8px;
        color: rgba(102, 102, 102, 1);
        font-size: 13px;
        text-align: left;
        font-family: PingFangSC-Regular;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .position-link{
        position: absolute;
        width: 48px;
        height: 17px;
        line-height: 17px;
        right: 15px;
        color: rgba(255, 93, 59, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .houseDetails{
        width: 100%;
        height: auto;
        background-color: white;
        margin-top: 15px;
        overflow: hidden;
    }
    .houseDetails-text{
        height: 24px;
        line-height: 24px;
        margin:20px 0 0 15px;
        color: rgba(34, 34, 34, 1);
        font-weight: bold;
        font-size: 17px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .furnitures{
        display: flex;
        margin-top: 9px;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
    }
    .furniture-one{
        width: 60px;
        height: 67px;
        margin-bottom: 30px;
        margin-left: 12.5px;
    }
    .furniture-one-icon{
        width: 40px;
        height: 40px;
        margin-left: 10px;
    }
    .furniture-one-text{
        width: 100%;
        height: 17px;
        margin-top: 10px;
        line-height: 17px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(102, 102, 102, 1);
        font-size: 12px;
        font-family: PingFangSC-Regular;
    }
    .houseDetails-describe{
        height: 24px;
        line-height: 24px;
        margin:0 0 0 15px;
        color: rgba(34, 34, 34, 1);
        font-weight: bold;
        font-size: 17px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .houseDetails-content{
        width: 100%;
        margin-top: 10px;
        height: auto;
    }
    .houseDetails-describe-details{
        width: 325px;
        height: auto;
        margin: 10px 25px 10px 25px;
        line-height: 20px;
        color: rgba(102, 102, 102, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
        /* 文字展开 */
        /*关键属性*/
        display: -webkit-box;
        color:#676767;
        word-break: break-all;
        /* 关键属性 */
        -webkit-box-orient: vertical;
        /* 显示几行文字 */
        -webkit-line-clamp:6;
        /* 关键属性 */
        overflow: hidden;
    }
    .hide{
        display: -webkit-box;
    }
    .show{
        display: block;
        margin-bottom: 28px;
    }
    .bottom-box{
        width: 100%;
        height: 50px;
        position: relative;
        box-shadow: 0px -10px 20px 20px #ffffff;
    }
    .showOrHidden{
        position: absolute;
        right: 15px;
        width: auto;
        height: 17px;
        line-height: 17px;
        color: rgba(233, 91, 46, 1);
        font-size: 12px;
        text-align: right;
        font-family: PingFangSC-Regular;
    }
    .bottom-img{
        width: 100%;
        height: 121px;
        margin-bottom: 65px;
        background-image: url("../../../assets/images/houseResourcesImg/quality Guarantee.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bottom-fixed{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 65px;
        display: flex;
        align-items: center;
        background-color: rgba(250, 250, 250, 1);
        box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.07);
    }
    .bottom-fixed-signed{
        width: 22px;
        height: 100%;
        margin-left: 32px;
    }
    .bottom-fixed-signed-icon{
        width: 20px;
        height: 20px;
        margin-top: 15px;
        margin-left: 1px;
        background-image: url("../../../assets/images/potentialHouse-icon1.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bottom-fixed-text{
        width: 22px;
        height: 16px;
        margin-top: 5px;
    }
    .bottom-fixed-text-11{
        width: 100%;
        height: 100%;
        line-height: 16px;
        color: black;
        white-space: nowrap;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        -webkit-transform: scale(0.92);
    }
    .bottom-fixed-order{
        width: 22px;
        height: 100%;
        margin-left: 41px;
    }
    .bottom-fixed-order-icon{
        width: 20px;
        height: 20px;
        margin-top: 15px;
        margin-left: 1px;
        background-image: url("../../../assets/images/houseResourcesImg/order.png");
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
    }
    .bottom-fixed-orderLook{
        width: 91px;
        height: 40px;
        line-height: 43px;
        margin-left: 48px;
        text-align: center;
        border-radius: 8px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        box-shadow: 0px 1px 1px 0px rgba(0, 115, 202, 0.5);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
    }
    .bottom-fixed-phoneRelation{
        width: 91px;
        height: 40px;
        line-height: 43px;
        margin-left: 15px;
        text-align: center;
        border-radius: 8px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        box-shadow: 0px 1px 1px 0px rgba(188, 0, 0, 0.2);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-family: PingFangSC-Semibold;
    }
</style>
